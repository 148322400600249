import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";

import ContainerDefault from "../../components/container";

import api from "../../services/api";

const Container = styled.div`
  form {
    padding-top: 26px;
    padding-bottom: 35px;
  }

  .input-text,
  .input-select {
    margin-bottom: 23px;
  }

  .input-error {
    border-bottom: 1px solid #bd0303;
    color: #bd0303;

    &::placeholder {
      color: #bd0303;
    }
  }
  .input-error-area {
    border: 1px solid #bd0303;
    color: #bd0303;

    &::placeholder {
      color: #bd0303;
    }
  }

  .password-section {
    p.error {
      color: #bd0303;
      font-size: 14px;
    }
  }

  .form-errors {
    background: #eba7a7;
    padding: 10px 0;
    text-align: center;

    ul li {
      font: italic bold 14px "Times new roman";
      color: #bd0000;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        width: 31px;
        height: 31px;
        background: url("/images/streamline-icon-alert-diamond-140-x-140.png")
          no-repeat center;
        background-size: contain;
        display: block;
        margin-right: 11px;
      }
    }
  }

  .half-mobile {
    display: flex;
    justify-content: space-between;

    input,
    select {
      width: 48%;
    }
  }

  .picture {
    padding-top: 12px;
    text-align: center;
    margin-bottom: 35px;

    > h3 {
      font: italic bold 22px "Times new roman";
      color: #000000;
      margin-bottom: 35px;
    }

    .img-profile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-bottom: 35px;
    }

    .img-profile-empty {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 0 auto 35px;

      background: #ffffff
        url("/images/streamline-icon-paginate-filter-picture-140-x-140.png")
        no-repeat center;
      background-size: 40px;
    }

    #picture-file {
      display: none;
    }

    .button-file {
      cursor: pointer;
      max-width: 280px;
      height: 50px;
      border-radius: 3px;
      border: 3px solid #f1a857;
      margin: 0 auto;

      display: flex;
      align-items: center;

      transition: 0.2s all ease;

      span {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        padding-left: 35px;
        padding-right: 15px;

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      i {
        width: 57px;
        height: 100%;
        border-left: 1px solid #f1a857;
        margin-left: auto;
        background: url("/images/streamline-icon-image-file-upload-140-x-140.png")
          center no-repeat;
        background-size: 24px;
      }

      &:hover {
        background: #f1a857;

        span {
          color: #ffffff;
        }

        i {
          border-left: 1px solid #e0933d;
        }
      }
    }
  }

  .terms-field {
    margin: 35px 0;

    p.error {
      color: #bd0303;
      font-size: 14px;
      text-align: center;
    }
  }

  .ready-terms {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: #000000;
    margin-bottom: 10px;

    input {
      border: 2px solid #979797;
      width: 26px;
      height: 26px;
      background: transparent;
      margin-right: 6px;
      appearance: none;
      border-radius: 3px;

      &:checked {
        border: 2px solid #ffffff;
        background: url(/images/check.svg) no-repeat center #fff;
      }
    }

    a {
      font-weight: 700;
      text-decoration: underline;
      color: #000000;
    }
  }

  .button-submit {
    cursor: pointer;
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border: 0;
    margin: 0 auto;
    background: #f1a857;

    display: flex;
    align-items: center;

    transition: 0.2s all ease;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 35px;
      padding-right: 30px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      width: 73px;
      height: 100%;
      border-left: 1px solid #d99440;
      margin-left: auto;
      background: url("/images/streamline-icon-send-email-140-x-140.png") center
        no-repeat;
      background-size: 24px;
    }

    &:hover {
      background: #e59134;

      span {
        color: #ffffff;
      }

      i {
        border-left: 1px solid #cd8226;
      }
    }
  }

  .section-fields {
    margin-bottom: 40px;

    > h3 {
      font: italic bold 30px "Times new roman";
      color: #000000;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      color: #000000;
      margin-bottom: 25px;
    }

    &.last-section {
      margin-bottom: 0;
    }
  }

  @media (min-width: 767px) {
    form {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    .input-text,
    .input-select {
      margin-bottom: 40px;
    }

    .half {
      display: flex;
      justify-content: space-between;

      input,
      select {
        width: 48%;
      }
    }

    .half-two {
      display: flex;
      justify-content: space-between;

      input {
        width: 48%;
      }
      & > div {
        width: 48%;
      }
    }

    .half-picture {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      & > div {
        width: 44%;
      }
      textarea {
        width: 54%;
      }
    }

    .password-section {
      p.error {
        padding-bottom: 20px;
      }

      .input-text {
        margin-bottom: 20px;
      }
    }

    .picture {
      margin-bottom: 0;
    }

    .terms-field {
      margin: 70px 0 40px;
    }

    .ready-terms {
      justify-content: center;

      font-size: 16px;
    }

    .button-submit {
      max-width: 380px;

      i {
        width: 83px;
      }
    }
  }

  @media (min-width: 1200px) {
    form {
      padding-top: 40px;
      padding-bottom: 80px;
      max-width: 962px;
      margin: 0 auto;
    }

    .half {
      input,
      select {
        width: 48.5%;
      }
    }

    .half-two {
      input {
        width: 48.5%;
      }
      & > div {
        width: 48.5%;
      }
    }

    .half-picture {
      & > div {
        width: 48.5%;
      }
      textarea {
        width: 48.5%;
      }
    }

    .terms-field {
      margin: 70px 0 65px;
    }
  }
`;

export default function RegisterFields() {
  const { register, handleSubmit, errors } = useForm();

  async function handleSendForm(data) {
    const dataForm = {
      email: data.email,
    };

    await api.post(
      "https://apimeuambiente.guararapes.com.br/user/password/",
      dataForm
    );

    alert("Por favor verifique seu email.");

    navigate("/");
  }

  return (
    <Container>
      {Object.keys(errors).length > 1 && (
        <div className="form-errors">
          <ul>
            <li>
              <i></i> Preencha todos os campos corretamente. Verifique abaixo:
            </li>
          </ul>
        </div>
      )}

      <ContainerDefault>
        <form onSubmit={handleSubmit(handleSendForm)}>
          <div className="section-fields">
            <h3>Preencha seu email</h3>
            <p>
              Por favor digite seu email para que possamos enviar as informações
              de redefinicão de senha.
            </p>

            <fieldset className="half">
              <input
                type="email"
                name="email"
                placeholder="Email *"
                className={
                  errors.email ? "input-text input-error" : "input-text"
                }
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Email invalido",
                  },
                })}
              />
            </fieldset>
          </div>

          <fieldset>
            <button type="submit" className="button-submit">
              <span>Enviar</span>
              <i></i>
            </button>
          </fieldset>
        </form>
      </ContainerDefault>
    </Container>
  );
}
